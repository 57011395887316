import { DocumentFields } from "../fragments/NodeDocumentFields";
import { tagify } from "./PdfAsset";
import { Tag } from "./Tag";

export class Document {
  title: string;
  tags: Tag[];
  file: { url: string; contentType: string };
  slug: string;
  constructor(node: DocumentFields) {
    this.title = node.title;
    this.tags = node.shelfSlugs?.shelfSlugs
      ? tagify(node.shelfSlugs.shelfSlugs)
      : [];
    this.file = node.file;
    this.slug = node.slug;
  }
}
