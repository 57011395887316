import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Col, Container } from "react-bootstrap";
import { WmkLink } from "wmk-link";
import { Document } from "../../classes/Document";
import { DocumentFields } from "../../fragments/NodeDocumentFields";
import { ColFirst, TableHeading, TableRowStyles } from "./Events/Events";
import PageHeader from "./PageHeader";

const DocumentTable = () => {
  const data: { documents: { edges: { node: DocumentFields }[] } } =
    useStaticQuery(query);

  const documents = data?.documents?.edges.map((document) => {
    return new Document(document.node);
  });

  return Array.isArray(documents) && documents.length ? (
    <>
      <PageHeader subhead={"Member Section"} headline={"FACT SHEETS"} />
      <Container style={{ marginTop: "4vh" }}>
        <TableHeading headings={["Title"]} />
        {documents.map((document, i) => {
          const title = document.title;
          return (
            <DocumentTableRow
              key={title + i}
              index={i}
              title={title}
              slug={document.slug}
            />
          );
        })}
      </Container>
    </>
  ) : null;
};

export default DocumentTable;

const DocumentTableRow = ({
  index,
  title,
  slug
}: {
  index: number;
  title: string;
  slug: string;
}) => {
  return (
    <TableRowStyles index={index} style={{ fontSize: "1rem" }}>
      <Col style={{ display: "flex", alignItems: "center" }}>
        <WmkLink to={`/docs/${slug}`}>{title}</WmkLink>
      </Col>
      {/* <ColMid>{author}</ColMid>
      <ColMid>{publicationDate}</ColMid> */}
    </TableRowStyles>
  );
};

const query = graphql`
  query {
    documents: allContentfulDocument(
      filter: { keywords: { in: "fact sheet" } }
    ) {
      edges {
        node {
          ...NodeDocumentFields
        }
      }
    }
  }
`;
