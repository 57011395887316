import * as React from "react";
import { RichTextReact } from "wmk-rich-text";
import get from "lodash/get";
import styled from "styled-components";
import { options } from "../../RichText/richTextOptions";
import { Resource } from "../../../../classes/Resource";
import { MemberSectionPage } from "../../../../classes/MemberSectionPage";

const Wrap = styled.div`
  padding: 2rem 3rem 2rem 1rem;
  & > .gatsby-image-wrapper {
    margin-left: calc(8.03% + 20px);
    @media screen and (max-width: 767px) {
      display: flex;
      max-width: 540px;
      margin-right: auto;
      margin-left: auto;
      & img {
        margin-left: 8.33%;
      }
      }
    }
  }
`;

const TechResContent = ({
  resource
}: {
  resource: Resource | MemberSectionPage;
}) => {
  return (
    <Wrap>
      <RichTextReact content={resource.content} options={options} />
    </Wrap>
  );
};

export default TechResContent;
