import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Type } from "./Typography";
// import { WmkLink } from "wmk-link";

const StyledPageHeader = styled.div`
  padding: 0 10px;
  .subtext {
    text-align: center;
    ul, ol {
      padding-left: 0;
      list-style-position: inside;
      li {
        margin-bottom: .5rem;
        p {
          display: inline;
        }
      }
    }
  }
  max-width: 800px;
  margin: auto;
`;

/**
 * Renders text header for internal pages
 */
type PageHeaderProps = {
  headline: string;
  subhead?: string;
  subtext?: string;
};

const PageHeader = ({ headline, subhead, subtext }: PageHeaderProps) => {
  return (
    <StyledPageHeader>
      <Container>
        <Row className="text-center ">
          <Col>
            <Type.PageSubhead>{subhead}</Type.PageSubhead>
            <Type.PageHeader className="headline">{headline}</Type.PageHeader>
            {typeof subtext === "string" ? (
              <Col
                className="subtext"
                md={{ span: 8, offset: 2 }}
                sm={12}
                xs={12}>
                <Type.Body>{subtext}</Type.Body>
              </Col>
            ) : (
              <Col
                className="subtext"
                md={{ span: 8, offset: 2 }}
                sm={12}
                xs={12}>
                {subtext}
              </Col>
            )}
          </Col>
        </Row>
      </Container>
    </StyledPageHeader>
  );
};

export default PageHeader;
